/* You can add global styles to this file, and also import other style files */
@import './src/assets/scss/colors';
@import './src/assets/scss/inputs';
@import './src/assets/scss/sidebar-and-main-panel';
@import '~highlight.js/styles/atom-one-dark.css';

@font-face {
  font-family: 'SamsungOne400';
  src: url('/assets/fonts/SamsungOne-400.ttf') format('truetype'),
  url('/assets/fonts/SamsungOne-400.woff') format('woff'),
  url('/assets/fonts/SamsungOne-400.woff2') format('woff2');
}

@font-face {
  font-family: 'SamsungOne700';
  src: url('/assets/fonts/SamsungOne-700.ttf') format('truetype'),
  url('/assets/fonts/SamsungOne-700.woff') format('woff'),
  url('/assets/fonts/SamsungOne-700.woff2') format('woff2');
}

@font-face {
  font-family: 'SamsungSharpSansBold';
  src: url('/assets/fonts/SamsungSharpSansBold.ttf') format('truetype'),
  url('/assets/fonts/SamsungSharpSansBold.woff') format('woff'),
  url('/assets/fonts/SamsungSharpSansBold.woff2') format('woff2');
}

body {
  position: relative;
  background: $color-background;
  width: 100vw;
  height: 100vh;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-overlay-container {
  z-index: 10000;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
