/* COLORS */
$color-primary: rgba(23, 165, 181, 1);
$color-blue-menu: rgba(16,55,92,1);
$color-blue-menu-title: rgba(30,69,106,1);
$color-secondary: rgba(23, 165, 181, 1);
$color-secondary-t20: rgba(18, 118, 129, 0.2);
// BACKGROUNDS
$color-background: rgba(229, 229, 229, 1);
$color-background-blue-1: rgba(0, 57, 112, 1);
$color-background-blue-2: rgba(12, 20, 70, 1);
$color-background-user-navbar: rgba(250, 250, 250, 1);
$color-background-pop-up: rgba(0, 0, 0, 0.2);
// INPUTS
$color-input-text: rgb(133, 133, 133);
$color-input-label: rgba(97, 104, 114, 1);
$color-input-border: rgba(181, 181, 181, 1);
$color-border-shadow: rgba(99, 99, 99, 0.15);
$color-input-error: rgba(99, 0, 0, 1);
$color-input-state-active: rgba(18, 118, 129, 1);
$color-input-state-active-bar: rgba(208, 244, 216, 1);
$color-input-error-components: rgba(152, 4, 4, 1);
$color-input-disabled: rgba(247, 247, 247, 1);
// TEXT
$color-text-primary: rgba(12, 20, 70, 1);
$color-text-secondary: rgba(0,57,112,1);
$color-text-tab-label-active: rgba(23, 165, 181, 1);
$color-text-sub-menu-active: rgba(23,165,181,1);
$color-text-gray: rgba(97, 104, 114, 1);
$color-text-gray-196: rgba(196, 196, 196, 1);
$color-text-success: rgba(18, 118, 129, 1);
$color-text-error: rgba(152, 4, 4, 1);
$color-text-alert: rgba(202, 158, 59, 1);
$color-white: rgba(255, 255, 255, 1);
$color-black: rgba(0, 0, 0, 1);
// BUTTONS AND LINKS
$color-button-main: rgba(23, 165, 181, 1);
$color-button-secondary: rgba(18, 118, 129, 1);
$color-button-border: rgba(23, 165, 181, 1);
$color-button-border-test: rgba(255, 210, 48, 1);
$color-button-border-hover: rgba(0, 0, 0, 1);
$color-link-text: rgba(23, 165, 181, 1);
$color-button-shadow: rgba(0, 0, 0, 0.15);
$color-button-text-cancel: rgba(97, 104, 114, 1);
// LINES
$color-hr-secondary: rgba(196, 196, 196, 1);
$color-hr-third: rgba(226, 226, 226, 1);
// BORDERS
$color-admin-content-border: rgba(196, 196, 196, 1);
// NOTIFICATIONS
$color-notification: rgba(254, 246, 216, 1);
$color-notification-info: rgba(223, 239, 253, 1);
$color-notification-success: rgba(208, 244, 216, 1);
$color-notification-error: rgba(255, 212, 214, 1);
$color-notification-shadow: rgba(97, 97, 97, 0.1);
// NOTIFICATIONS
$color-background-loader: rgba(255, 255, 255, 0.5);
// TABLES
$color-table-border: rgba(196, 196, 196, 1);
$color-table-text: rgba(40, 40, 40, 1);
$color-table-pair-background: rgba(246, 246, 246, 1);
$color-table-text-paginator: rgba(97, 104, 114, 1);
$color-table-checkbox: rgba(23, 165, 181, 1);
// CHAT
$color-chat-user: rgba(255, 255, 255, 1);
$color-chat-admin: rgba(23, 165, 181, 1);
$color-chat-admin-reply: rgba(255, 255, 255, 1);
$color-chat-admin-reply-msg: rgba(246, 246, 246, 1);
$color-chat-admin-response-bg: rgba(246, 246, 246, 1);


/* END COLORS */
