@import './colors'; /* Colors */
@import './fonts'; /* Colors */

/* INPUTS */
.mat-form-field-underline {
  display: none;
}

.mat-focused .mat-form-field-required-marker,
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow,
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent, .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
.mat-form-field-required-marker {
  color: $color-input-error-components !important;
}

.mat-form-field-wrapper {
  padding-bottom: 15px;
}

.mat-form-field-infix {
  border-bottom: none;
  padding: 10px 0 0;

  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled {
    background-color: $color-input-disabled !important;
  }

  label {
    font-family: SamsungOne400;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: $color-input-label !important;
  }

  input {
    position: relative !important;
    width: 100% !important;
    height: 40px !important;
    background-color: $color-white !important;
    border: 1px solid $color-input-border !important;
    box-sizing: border-box !important;
    padding: 0 20px;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    font-family: SamsungOne400;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-input-text;
  }

  .mat-select {
    position: relative !important;
    width: 100% !important;
    height: 40px !important;
    background-color: $color-white !important;
    border: 1px solid $color-input-border !important;
    box-sizing: border-box !important;
    padding: 0 20px;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    font-family: SamsungOne400;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $color-input-text;
    vertical-align: text-bottom;
    .mat-select-trigger {
      height: 100%;
      .mat-select-value {
        vertical-align: middle;
      }
    }
  }

  .icon-help {
    cursor: pointer;
    top: -10px;
    right: 0;
    position: absolute;
    font-size: 14px;
    width: 14px;
    height: 14px;
    color: $color-input-label;
  }

  .div-help {
    position: absolute;
    bottom: 50px;
    width: 100%;
    max-width: 200px;
    height: auto;
    background-color: #dfeffd;
    box-shadow: 0 0 10px 1px rgb(97 97 97 / 10%);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    right: 20px;
    padding: 5px;
    z-index: 1;
    h1, p {
      font-family: SamsungOne400 !important;
      font-size: 10px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 14px !important;
      letter-spacing: 0 !important;
      color: $color-text-secondary !important;
      margin-bottom: 0 !important;
    }
    h1.help-title {
      font-family: SamsungOne700 !important;
      font-size: 12px !important;
      text-align: center!important;
      width: 100% !important;
      margin-bottom: 5px !important;
    }
  }

  p.show {
    position: absolute;
    bottom: 4px;
    right: 10px;
    cursor: pointer;
    // Typography
    font-family: SamsungOne400;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    color: $color-input-border;
    margin-bottom: 16px;
    &:hover {
      color: $color-input-text;
    }
  }

  p.error {
    position: absolute;
    top: 45px;
    left: 0;
    // Typography
    font-family: SamsungOne400;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    color: $color-input-error;
    margin-bottom: 0;
    @media (max-width: 991px) {
      font-size: 10px;
      line-height: 12px;
    }
    @media (max-width: 767px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.mat-form-field-appearance-standard {
  .mat-form-field-wrapper {
    padding-bottom: 0;
    .mat-form-field-flex {
      padding-top: 0;
      p.show {
        margin-bottom: 8px;
      }
    }
  }
}
